<template>
  <div>
    <h4>{{ title }}</h4>
    <br>

    <v-btn
      v-if="isSlideFormOpen === false"
      x-small
      :color="'primary'"
      @click="isSlideFormOpen = true"
    >
      <span>
        Ajouter des images
      </span>
    </v-btn>

    <div
      v-if="isSlideFormOpen"
      class="upload"
    >
      <v-row>
        <v-col
          cols="7"
          md="7"
        >
          <v-file-input
            v-model="uploadedSlides"
            counter
            show-size
            multiple
            small-chips
            placeholder="Choisir des fichiers"
            truncate-length="25"
            :prepend-icon="null"
            @change="chooseFiles"
          >
            <template #selection="{ text }">
              <v-chip
                small
                label
                outlined
                color="primary"
              >
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>
        </v-col>
      </v-row>
    </div>

    <div
      v-if="entityData[attributeName].length"
      class="slider mt-5"
    >
      <v-row>
        <v-col
          cols="7"
          md="7"
        >
          <v-carousel
            class="float-left carousel-slider mr-5"
            hide-delimiters
          >
            <v-carousel-item
              v-for="(slide,i) in entityData[attributeName]"
              :key="i"
              :src="apiUrl() + '/media/' + slide.id"
            ></v-carousel-item>
          </v-carousel>
        </v-col>
        <v-col
          cols="5"
          md="5"
        >
          <Draggable
            class="list-group float-left mr-5"
            ghost-class="ghost"
            :list="entityData[attributeName]"
            :disabled="false"
            @start="isDragging = true"
            @end="isDragging = false"
          >
            <div
              v-for="slide in entityData[attributeName]"
              :key="slide.id"
              class="list-group-item"
            >
              <div class="float-left">
                <span class="list-item-label">{{ slide.title }}</span>
              </div>

              <div
                class="float-right"
              >
                <v-btn
                  :color="slide.enabled ? 'success' : 'error'"
                  fab
                  dark
                  x-small
                  @click="toggleVisibility(slide)"
                >
                  <v-icon dark>
                    {{ slide.enabled ? icons.mdiEyeOutline : icons.mdiEyeOffOutline }}
                  </v-icon>
                </v-btn>

                <v-btn
                  color="error"
                  fab
                  dark
                  x-small
                  @click="removeSlide(slide)"
                >
                  <v-icon dark>
                    {{ icons.mdiMinusBox }}
                  </v-icon>
                </v-btn>
              </div>

              <div class="clear"></div>
            </div>
          </Draggable>
        </v-col>
      </v-row>
    </div>
    <div class="clear"></div>
  </div>
</template>

<script>
import Draggable from 'vuedraggable'
import config from '@/config'
import Vue from 'vue'

export default {
  name: 'SliderHandlerV2',
  components: {
    Draggable
  },
  props: {
    entityData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    title: {
      type: String,
      default: () => {
        return null
      }
    },
    attributeName: {
      type: String,
      default: () => {
        return null
      }
    },
    dragging: Boolean,
    showSlideForm: Boolean,
    icons: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      uploadedSlides: [],
      uploadQueue: [],
      uploadAvailable: true,
      isSlideFormOpen: this.showSlideForm,
      isDragging: this.dragging
    }
  },
  created () {
    if (this.entityData[this.attributeName].length > 0) {
      if (this.entityData[this.attributeName][0].enabled === undefined) {
        this.entityData[this.attributeName].forEach(element => {
          element.enabled = true
        })
      }
    }
  },
  methods: {
    chooseFiles (files) {
      if (files.length > 0) {
        const sortedFiles = files.sort((a, b) => a.name.localeCompare(b.name))

        for (let i = 0; i < sortedFiles.length; i++) {
          const file = files[i]

          this.uploadQueue.push(file)
        }

        if (this.uploadAvailable) {
          this.handleUpload(this.uploadQueue[0])
        }
      }
    },
    handleUpload (file) {
      this.uploadAvailable = false
      this.uploadQueue = this.uploadQueue.filter(element => element.name !== file.name)

      this.addSlide(file)
    },
    addSlide (file) {
      this.valid = false
      this.loading = true

      const formData = new FormData()

      formData.append('media', file)

      Vue.prototype.$http
        .post(config.apiUrl + '/api/medias', formData, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken,
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          if (response.status === 201) {
            const media = response.data

            const slide = {
              id : media.id,
              title: media.title,
              name: media.name,
              enabled: true
            }

            this.entityData.sliderImages.push(slide)

            this.showSlideForm = false

            this.errors = []
            this.snackbar = true
            this.successMessage = 'Image envoyé avec succès.'

            if (this.uploadQueue.length) {
              this.handleUpload(this.uploadQueue[0])
            } else {
              this.uploadAvailable = true
            }
          }

          this.valid = true
          this.loading = false
        })
        .catch(e => {
          const response = e.response

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
          }

          this.valid = true
          this.loading = false
        })
    },
    apiUrl () {
      return config.apiUrl
    },
    removeSlide (selectedSlide) {
      this.$emit('removeSlide', selectedSlide)
    },
    toggleVisibility (selectedSlide) {
      const index = this.entityData[this.attributeName].findIndex(element => element.id === selectedSlide.id)

      if (this.entityData[this.attributeName][index].enabled === null || this.entityData[this.attributeName][index].enabled === undefined) {
        this.entityData[this.attributeName].splice(index, 1, {
          ...this.entityData[this.attributeName][index],
          enabled: true
        })
      } else {
        this.entityData[this.attributeName].splice(index, 1, {
          ...this.entityData[this.attributeName][index],
          enabled: !this.entityData[this.attributeName][index].enabled
        })
      }
    }
  }
}
</script>

<style scoped>
  .list-group {
    width: 100%;
  }
  .list-group-item {
    position: relative;
    display: block;
    padding: 0.35rem 0.5rem;
    margin-bottom: -1px;
    background-color: #fff;
    border-top: 2px dotted rgba(0,0,0,.125);
    border-bottom: 2px dotted rgba(0,0,0,.125);
  }
  .list-group-item:hover {
    cursor: pointer;
  }
  .list-group-item button {
    margin-right: 10px;
  }
  .list-item-label {
    vertical-align: sub;
  }
</style>
