<template>
  <v-card>
    <Loader
      v-if="loading"
      :key="generateUniqueId()"
      :loader-state="loading"
    ></Loader>

    <v-card-title class="float-left">
      Détails d'un camping : {{ camping ? camping.translations.fr.name : '-' }}
    </v-card-title>

    <LocalSwitcher
      @updateCurrentLocale="updateCurrentLocale($event)"
    ></LocalSwitcher>

    <v-card-text>
      <v-form
        v-model="valid"
        class="multi-col-validation mt-5"
        @submit.prevent="checkForm"
      >
        <p v-if="errors.length">
          <v-alert
            v-for="(error, index) in errors"
            :key="index"
            text
            dense
            type="error"
          >
            {{ error.title }}
          </v-alert>
        </p>

        <Snackbar
          v-if="successMessage"
          :key="generateUniqueId()"
          :snackbar-state="snackbar"
          :success-message="successMessage"
        ></Snackbar>

        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-tabs
              grow
            >
              <v-tab>
                <v-icon left>
                  {{ icons.mdiEyeOutline }}
                </v-icon>
                General
              </v-tab>
              <v-tab>
                <v-icon left>
                  {{ icons.mdiPencil }}
                </v-icon>
                CMS
              </v-tab>
              <v-tab>
                <v-icon left>
                  {{ icons.mdiCompassRose }}
                </v-icon>
                Localisation
              </v-tab>
              <v-tab>
                <v-icon left>
                  {{ icons.mdiThumbUp }}
                </v-icon>
                Avantages
              </v-tab>
              <v-tab>
                <v-icon left>
                  {{ icons.mdiSkiWater }}
                </v-icon>
                Activités
              </v-tab>
              <v-tab>
                <v-icon left>
                  {{ icons.mdiCameraOutline }}
                </v-icon>
                Images
              </v-tab>

              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col
                        cols="6"
                        md="6"
                      >
                        <h4>Details du camping</h4>
                        <br>
                        <v-text-field
                          v-model="name[currentLocale]"
                          label="Nom du camping"
                          class="mb-5"
                          dense
                          readonly
                          placeholder="Nom du camping"
                          hide-details
                        >
                          <template v-slot:append>
                            <country-flag
                              class="inner-input-flag-readonly"
                              :country="currentLocale === 'en' ? 'gb' : currentLocale"
                              size="normal"
                              shadow
                            />
                          </template>
                        </v-text-field>

                        <v-text-field
                          v-model="slug[currentLocale]"
                          label="Slug du camping"
                          class="mb-5"
                          dense
                          readonly
                          placeholder="Slug du camping"
                          hide-details
                        >
                          <template v-slot:append>
                            <country-flag
                              class="inner-input-flag-readonly"
                              :country="currentLocale === 'en' ? 'gb' : currentLocale"
                              size="normal"
                              shadow
                            />
                          </template>
                        </v-text-field>

                        <h4>Coordonnées</h4>
                        <br>
                        <v-text-field
                          v-model="entityData.manager"
                          label="Gérant"
                          class="mb-5"
                          dense
                          readonly
                          placeholder="Gérant"
                          hide-details
                        ></v-text-field>

                        <v-text-field
                          v-model="entityData.address"
                          label="Adresse"
                          class="mb-5"
                          dense
                          readonly
                          placeholder="Adresse"
                          hide-details
                        ></v-text-field>

                        <v-text-field
                          v-model="entityData.zipCode"
                          label="Code postal"
                          class="mb-5"
                          dense
                          readonly
                          placeholder="Code postal"
                          hide-details
                        ></v-text-field>

                        <v-text-field
                          v-model="entityData.email"
                          label="Email"
                          class="mb-5"
                          dense
                          readonly
                          placeholder="Email"
                          hide-details
                        ></v-text-field>

                        <v-text-field
                          v-model="entityData.website"
                          label="Site internet"
                          class="mb-5"
                          dense
                          readonly
                          placeholder="Site internet"
                          hide-details
                        ></v-text-field>

                        <v-text-field
                          v-model="entityData.phone"
                          label="Téléphone"
                          class="mb-5"
                          dense
                          readonly
                          placeholder="Téléphone"
                          hide-details
                        ></v-text-field>

                        <v-text-field
                          v-model="entityData.latitude"
                          label="Latitude"
                          class="mb-5"
                          dense
                          readonly
                          placeholder="Latitude"
                          hide-details
                        ></v-text-field>

                        <v-text-field
                          v-model="entityData.longitude"
                          label="Longitude"
                          class="mb-5"
                          dense
                          readonly
                          placeholder="Longitude"
                          hide-details
                        ></v-text-field>

                        <h4>Ouverture / fermeture locations</h4>
                        <br>
                        <v-text-field
                          dense
                          :value="formatDate(entityData.rentalOpening)"
                          label="Date d'ouverture des locations"
                          readonly
                        ></v-text-field>

                        <v-text-field
                          :value="formatDate(entityData.rentalClosing)"
                          label="Date de fermeture des locations"
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="6"
                        md="6"
                      >
                        <h4>Identifiants externe</h4>
                        <br>
                        <v-text-field
                          v-model="entityData.flowerDataId"
                          label="ID Flower data"
                          class="mb-5"
                          dense
                          readonly
                          placeholder="ID Flower data"
                          hide-details
                        ></v-text-field>

                        <v-text-field
                          v-model="entityData.secureHolidayId"
                          label="ID Secure Holiday"
                          class="mb-5"
                          dense
                          readonly
                          placeholder="ID Secure Holiday"
                          hide-details
                        ></v-text-field>

                        <h4>Ambiance & inventaire</h4>
                        <br>
                        <v-text-field
                          v-model="entityData.nbOfRentals"
                          label="Nombre de locations"
                          class="mb-5"
                          dense
                          readonly
                          placeholder="Nombre de locations"
                          hide-details
                        ></v-text-field>

                        <v-text-field
                          v-model="entityData.tonicLvl"
                          label="Tonique"
                          class="mb-5"
                          dense
                          readonly
                          placeholder="Tonique"
                          hide-details
                        ></v-text-field>

                        <v-text-field
                          v-model="entityData.zenLvl"
                          label="Zen"
                          class="mb-5"
                          dense
                          readonly
                          placeholder="Zen"
                          hide-details
                        ></v-text-field>

                        <v-text-field
                          v-model="entityData.funLvl"
                          label="Fun"
                          class="mb-5"
                          dense
                          readonly
                          placeholder="Fun"
                          hide-details
                        ></v-text-field>

                        <v-text-field
                          v-model="entityData.entertainmentLvl"
                          label="Divertissement"
                          class="mb-5"
                          dense
                          readonly
                          placeholder="Divertissement"
                          hide-details
                        ></v-text-field>

                        <h4>Avis clients</h4>
                        <br>
                        <v-text-field
                          v-model="entityData.globalRating"
                          label="Note global"
                          class="mb-5"
                          dense
                          readonly
                          placeholder="Note global"
                          hide-details
                        ></v-text-field>

                        <v-text-field
                          v-model="entityData.reviewsCount"
                          label="Nombre de note"
                          class="mb-5"
                          dense
                          readonly
                          placeholder="Nombre de note"
                          hide-details
                        ></v-text-field>

                        <h4>Ouverture / fermeture emplacements</h4>
                        <br>
                        <v-text-field
                          :value="formatDate(entityData.pitchOpening)"
                          label="Date d'ouverture des emplacements"
                          readonly
                        ></v-text-field>

                        <v-text-field
                          :value="formatDate(entityData.pitchClosing)"
                          label="Date de fermeture des emplacements"
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col
                        cols="6"
                        md="6"
                      >
                        <h4>Description du camping</h4>
                        <br>
                        <v-text-field
                          v-model="title[currentLocale]"
                          label="Titre"
                          class="mb-5"
                          outlined
                          dense
                          placeholder="Titre"
                          hide-details
                        >
                          <template v-slot:append>
                            <country-flag
                              class="inner-input-flag"
                              :country="currentLocale === 'en' ? 'gb' : currentLocale"
                              size="normal"
                              shadow
                            />
                          </template>
                        </v-text-field>

                        <v-text-field
                          v-model="managerQuote[currentLocale]"
                          label="Citation du gérant"
                          class="mb-5"
                          outlined
                          dense
                          placeholder="Citation du gérant"
                          hide-details
                        >
                          <template v-slot:append>
                            <country-flag
                              class="inner-input-flag"
                              :country="currentLocale === 'en' ? 'gb' : currentLocale"
                              size="normal"
                              shadow
                            />
                          </template>
                        </v-text-field>

                        <v-text-field
                          v-model="pluses[currentLocale]"
                          label="Les + du camping (séparés par des points virgules)"
                          class="mb-5"
                          outlined
                          dense
                          placeholder="Les + du camping"
                          hide-details
                        >
                          <template v-slot:append>
                            <country-flag
                              class="inner-input-flag"
                              :country="currentLocale === 'en' ? 'gb' : currentLocale"
                              size="normal"
                              shadow
                            />
                          </template>
                        </v-text-field>

                        <h4>Configuration Customer Alliance</h4>
                        <br>

                        <v-text-field
                          v-model="entityData.customerAllianceId"
                          label="Identifiant Customer Alliance"
                          class="mb-5"
                          outlined
                          dense
                          placeholder="Identifiant Customer Alliance"
                          hide-details
                        ></v-text-field>

                        <v-text-field
                          v-model="entityData.customerAllianceApiId"
                          label="Identifiant Api Customer Alliance"
                          class="mb-5"
                          outlined
                          dense
                          placeholder="Identifiant Api Customer Alliance"
                          hide-details
                        ></v-text-field>

                        <v-text-field
                          v-model="entityData.customerAllianceApiAccessKey"
                          label="Clé d'accès Api Customer Alliance"
                          class="mb-5"
                          outlined
                          dense
                          placeholder="Clé d'accès Api Customer Alliance"
                          hide-details
                        ></v-text-field>

                        <h4>Statuts et configuration</h4>
                        <br>

                        <v-text-field
                          v-model="entityData.urlRedirect"
                          label="Url de redirection"
                          class="mb-5"
                          outlined
                          dense
                          placeholder="Url de redirection"
                          hide-details
                        ></v-text-field>

                        <v-text-field
                          v-model="entityData.stars"
                          label="Nombre d'étoile"
                          class="mb-5"
                          outlined
                          dense
                          placeholder="Nombre d'étoile"
                          hide-details
                        ></v-text-field>

                        <v-text-field
                          v-model="entityData.googleLabel"
                          label="Label Google"
                          class="mb-5"
                          outlined
                          dense
                          placeholder="Label Google"
                          hide-details
                        ></v-text-field>

                        <v-text-field
                          v-model="entityData.weight"
                          label="Poids"
                          class="mb-5"
                          outlined
                          dense
                          placeholder="Poids"
                          hide-details
                        ></v-text-field>

                        <v-text-field
                          v-model="entityData.video"
                          label="Video"
                          class="mb-5"
                          outlined
                          dense
                          placeholder="Video"
                          hide-details
                        ></v-text-field>

                        <v-switch
                          v-model="entityData.enabled"
                          :label="'Etat : ' + (entityData.enabled === true ? 'Activé' : 'Désactivé')"
                        ></v-switch>

                        <v-switch
                          v-model="entityData.new"
                          :label="'Nouveau : ' + (entityData.new === true ? 'Oui' : 'Non')"
                        ></v-switch>

                        <v-switch
                          v-model="entityData.naturist"
                          :label="'Naturiste : ' + (entityData.naturist === true ? 'Oui' : 'Non')"
                        ></v-switch>

                        <h4>SEO</h4>
                        <br>

                        <v-text-field
                          v-model="seoTitle[currentLocale]"
                          label="Titre SEO"
                          class="mb-5"
                          outlined
                          dense
                          placeholder="Titre SEO"
                          hide-details
                        >
                          <template v-slot:append>
                            <country-flag
                              class="inner-input-flag"
                              :country="currentLocale === 'en' ? 'gb' : currentLocale"
                              size="normal"
                              shadow
                            />
                          </template>
                        </v-text-field>

                        <v-text-field
                          v-model="seoDescription[currentLocale]"
                          label="Description SEO"
                          class="mb-5"
                          outlined
                          dense
                          placeholder="Description SEO"
                          hide-details
                        >
                          <template v-slot:append>
                            <country-flag
                              class="inner-input-flag"
                              :country="currentLocale === 'en' ? 'gb' : currentLocale"
                              size="normal"
                              shadow
                            />
                          </template>
                        </v-text-field>

                        <v-text-field
                          v-model="seoKeywords[currentLocale]"
                          label="Mot clés"
                          class="mb-5"
                          outlined
                          dense
                          placeholder="Mot clés"
                          hide-details
                        >
                          <template v-slot:append>
                            <country-flag
                              class="inner-input-flag"
                              :country="currentLocale === 'en' ? 'gb' : currentLocale"
                              size="normal"
                              shadow
                            />
                          </template>
                        </v-text-field>

                        <h4>Block RSE</h4>
                        <br>

                        <v-text-field
                          v-model="rseTxt1[currentLocale]"
                          label="RSE text zone 1"
                          class="mb-5"
                          outlined
                          dense
                          placeholder="RSE text zone 1"
                          hide-details
                        >
                          <template v-slot:append>
                            <country-flag
                              class="inner-input-flag"
                              :country="currentLocale === 'en' ? 'gb' : currentLocale"
                              size="normal"
                              shadow
                            />
                          </template>
                        </v-text-field>

                        <v-text-field
                          v-model="rseTxt2[currentLocale]"
                          label="RSE text zone 2"
                          class="mb-5"
                          outlined
                          dense
                          placeholder="RSE text zone 2"
                          hide-details
                        >
                          <template v-slot:append>
                            <country-flag
                              class="inner-input-flag"
                              :country="currentLocale === 'en' ? 'gb' : currentLocale"
                              size="normal"
                              shadow
                            />
                          </template>
                        </v-text-field>

                        <v-text-field
                          v-model="rseSubtitle[currentLocale]"
                          label="RSE sous-titre"
                          class="mb-5"
                          outlined
                          dense
                          placeholder="RSE sous-titre"
                          hide-details
                        >
                          <template v-slot:append>
                            <country-flag
                              class="inner-input-flag"
                              :country="currentLocale === 'en' ? 'gb' : currentLocale"
                              size="normal"
                              shadow
                            />
                          </template>
                        </v-text-field>

                        <div class="content-ckeditor-region">
                          <h4>Description</h4>
                          <br>
                          <ckeditor
                            v-model="rseDescription[currentLocale]"
                            :editor="editor"
                            :config="editorConfig"
                            height="10"
                            row="50"
                          />
                          <country-flag
                            class="inner-input-flag content-flag-placeholder"
                            :country="currentLocale === 'en' ? 'gb' : currentLocale"
                            size="normal"
                            shadow
                          />
                        </div>
                      </v-col>
                      <v-col
                        cols="6"
                        md="6"
                      >
                        <div class="content-ckeditor-region">
                          <h4>Résumé</h4>
                          <br>
                          <ckeditor
                            v-model="summary[currentLocale]"
                            :editor="editor"
                            :config="editorConfig"
                            height="10"
                            row="50"
                          />
                          <country-flag
                            class="inner-input-flag content-flag-placeholder"
                            :country="currentLocale === 'en' ? 'gb' : currentLocale"
                            size="normal"
                            shadow
                          />
                        </div>

                        <br>

                        <div class="content-ckeditor-region">
                          <h4>Description courte</h4>
                          <br>
                          <ckeditor
                            v-model="shortDescription[currentLocale]"
                            :editor="editor"
                            :config="editorConfig"
                            height="10"
                            row="50"
                          />
                          <country-flag
                            class="inner-input-flag content-flag-placeholder"
                            :country="currentLocale === 'en' ? 'gb' : currentLocale"
                            size="normal"
                            shadow
                          />
                        </div>

                        <br>

                        <div class="content-ckeditor-region">
                          <h4>Description Longue</h4>
                          <br>
                          <ckeditor
                            v-model="longDescription[currentLocale]"
                            :editor="editor"
                            :config="editorConfig"
                            height="10"
                            row="50"
                          />
                          <country-flag
                            class="inner-input-flag content-flag-placeholder"
                            :country="currentLocale === 'en' ? 'gb' : currentLocale"
                            size="normal"
                            shadow
                          />
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <h4>Localisation</h4>
                    <br>

                    <v-row>
                      <v-col
                        cols="6"
                        md="6"
                      >
                        <v-text-field
                          v-model="entityData.address"
                          label="Adresse"
                          class="mb-5"
                          dense
                          readonly
                          placeholder="Adresse"
                          hide-details
                        ></v-text-field>

                        <v-text-field
                          v-model="entityData.zipCode"
                          label="Code postal"
                          class="mb-5"
                          dense
                          readonly
                          placeholder="Code postal"
                          hide-details
                        ></v-text-field>

                        <v-text-field
                          v-model="entityData.latitude"
                          label="Latitude"
                          class="mb-5"
                          dense
                          readonly
                          placeholder="Latitude"
                          hide-details
                        ></v-text-field>

                        <v-text-field
                          v-model="entityData.longitude"
                          label="Longitude"
                          class="mb-5"
                          dense
                          readonly
                          placeholder="Longitude"
                          hide-details
                        ></v-text-field>

                        <h4>Sélection de la region web</h4>
                        <br>
                        <v-select
                          v-model="regionWebSelection"
                          :items="regionsWeb"
                          outlined
                          chips
                          persistent-hint
                        ></v-select>
                      </v-col>
                      <v-col
                        cols="6"
                        md="6"
                      >
                        <v-text-field
                          v-model="city[currentLocale]"
                          label="Ville"
                          class="mb-5"
                          dense
                          readonly
                          placeholder="Ville"
                          hide-details
                        >
                          <template v-slot:append>
                            <country-flag
                              class="inner-input-flag-readonly"
                              :country="currentLocale === 'en' ? 'gb' : currentLocale"
                              size="normal"
                              shadow
                            />
                          </template>
                        </v-text-field>

                        <v-text-field
                          v-if="entityData.department.translations"
                          v-model="entityData.department.translations[currentLocale].name"
                          label="Département"
                          class="mb-5"
                          dense
                          readonly
                          placeholder="Département"
                          hide-details
                        >
                          <template v-slot:append>
                            <country-flag
                              class="inner-input-flag-readonly"
                              :country="currentLocale === 'en' ? 'gb' : currentLocale"
                              size="normal"
                              shadow
                            />
                          </template>
                        </v-text-field>

                        <v-text-field
                          v-if="entityData.region.translations"
                          v-model="entityData.region.translations[currentLocale].name"
                          label="Région"
                          class="mb-5"
                          dense
                          readonly
                          placeholder="Région"
                          hide-details
                        >
                          <template v-slot:append>
                            <country-flag
                              class="inner-input-flag-readonly"
                              :country="currentLocale === 'en' ? 'gb' : currentLocale"
                              size="normal"
                              shadow
                            />
                          </template>
                        </v-text-field>

                        <vue-tags-input
                          class="camping"
                          v-model="tag"
                          :tags="tags"
                          placeholder="Tags"
                          @tags-changed="newTags => {
                            tags = newTags
                          }"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <h4>Avantages</h4>
                    <br>

                    <Draggable
                      class="list-group float-left mr-5 w-full"
                      ghost-class="ghost"
                      :list="entityData.advantages"
                      :disabled="false"
                      @start="dragging = true"
                      @end="dragging = false"
                    >
                      <div
                        v-for="(advantage, advantageIndex) in entityData.advantages"
                        :key="advantageIndex"
                        class="list-group-item"
                      >
                        <v-row>
                          <v-col
                            cols="6"
                            md="6"
                          >
                            <v-textarea
                              v-if="advantage.description"
                              v-model="advantage.description[currentLocale]"
                              label="Description"
                              class="mb-5"
                              outlined
                              dense
                              placeholder="Description"
                              hide-details
                            >
                              <template v-slot:append>
                                <country-flag
                                  class="inner-input-flag"
                                  :country="currentLocale === 'en' ? 'gb' : currentLocale"
                                  size="normal"
                                  shadow
                                />
                              </template>
                            </v-textarea>
                          </v-col>
                        </v-row>
                        <div class="clear"></div>

                        <v-btn
                          x-small
                          color="error"
                          class="mb-2"
                          @click="removeAdvantageForm(advantageIndex)"
                        >
                          <v-icon
                            small
                            dark
                          >
                            {{ icons.mdiMinusCircleOutline }}
                          </v-icon>
                          <span class="ml-2">Suppression de l'avantage</span>
                        </v-btn>

                        <v-row>
                          <v-col
                            cols="6"
                            md="6"
                          >
                            <v-divider class="v-divider-entity mt-2 mb-5"></v-divider>
                          </v-col>
                        </v-row>
                      </div>
                    </Draggable>

                    <div class="clear"></div>

                    <v-btn
                      x-small
                      color="primary"
                      @click="addAdvantageForm()"
                    >
                      <v-icon
                        small
                        dark
                      >
                        {{ icons.mdiPlusCircleOutline }}
                      </v-icon>
                      <span class="ml-2">Ajouter un avantage</span>
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-card-text>
                      <h4>Activités</h4>
                      <br>

                      <Draggable
                        class="list-group float-left mr-5 w-full"
                        ghost-class="ghost"
                        :list="entityData.activities"
                        :disabled="false"
                        @start="dragging = true"
                        @end="dragging = false"
                      >
                        <div
                          v-for="(activity, activityIndex) in entityData.activities"
                          :key="activityIndex"
                          class="list-group-item"
                        >
                          <v-row>
                            <v-col
                              cols="6"
                              md="6"
                            >
                              <v-file-input
                                v-if="!activity.image.id"
                                v-model="activity.file"
                                counter
                                show-size
                                small-chips
                                placeholder="Choisir un fichier"
                                truncate-length="25"
                                :prepend-icon="null"
                                @change="updateSubImageFile(activity)"
                              >
                                <template #selection="{ text }">
                                  <v-chip
                                    small
                                    label
                                    outlined
                                    color="primary"
                                  >
                                    {{ text }}
                                  </v-chip>
                                </template>
                              </v-file-input>

                              <div
                                v-if="activity.image"
                                class="preview"
                              >
                                <img
                                  v-if="activity.image.id || activity.preview"
                                  :src="activity.preview ? activity.preview : apiUrl() + '/media/' + activity.image.id"
                                  alt="activity"
                                  class="float-left"
                                />
                                <v-btn
                                  v-if="activity.image.id || (activity.preview && !activity.image.id)"
                                  x-small
                                  class="mr-2 float-left"
                                  :color="activity.preview && !activity.image.id ? 'success' : 'warning'"
                                  @click="activity.preview && !activity.image.id ? uploadSubImage(activity) : editSubImage(activity)"
                                >
                                  <span v-if="activity.preview && !activity.image.id">
                                    Valider l'image
                                  </span>
                                  <span v-else>
                                    Modifier l'image
                                  </span>
                                </v-btn>
                                <div class="clear"></div>
                              </div>

                              <br>

                              <v-text-field
                                v-if="activity.title"
                                v-model="activity.title[currentLocale]"
                                label="Titre"
                                class="mb-5"
                                outlined
                                dense
                                placeholder="Titre"
                                hide-details
                              >
                                <template v-slot:append>
                                  <country-flag
                                    class="inner-input-flag"
                                    :country="currentLocale === 'en' ? 'gb' : currentLocale"
                                    size="normal"
                                    shadow
                                  />
                                </template>
                              </v-text-field>

                              <div class="content-ckeditor-region">
                                <h4>Description</h4>
                                <br>
                                <ckeditor
                                  v-if="activity.description"
                                  v-model="activity.description[currentLocale]"
                                  :editor="editor"
                                  :config="editorConfig"
                                  height="10"
                                  row="50"
                                />
                                <country-flag
                                  class="inner-input-flag content-flag-placeholder"
                                  :country="currentLocale === 'en' ? 'gb' : currentLocale"
                                  size="normal"
                                  shadow
                                />
                              </div>

                              <br>

                              <v-text-field
                                v-model="activity.website"
                                label="Url de redirection"
                                class="mb-5"
                                outlined
                                dense
                                placeholder="Url de redirection"
                                hide-details
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <div class="clear"></div>

                          <v-btn
                            x-small
                            color="error"
                            class="mb-2"
                            @click="removeActivityForm(activityIndex)"
                          >
                            <v-icon
                              small
                              dark
                            >
                              {{ icons.mdiMinusCircleOutline }}
                            </v-icon>
                            <span class="ml-2">Suppression de l'activité</span>
                          </v-btn>

                          <v-row>
                            <v-col
                              cols="6"
                              md="6"
                            >
                              <v-divider class="v-divider-entity mt-2 mb-5"></v-divider>
                            </v-col>
                          </v-row>
                        </div>
                      </Draggable>

                      <div class="clear"></div>

                      <v-btn
                        x-small
                        color="primary"
                        @click="addActivityForm()"
                      >
                        <v-icon
                          small
                          dark
                        >
                          {{ icons.mdiPlusCircleOutline }}
                        </v-icon>
                        <span class="ml-2">Ajouter une activité</span>
                      </v-btn>
                    </v-card-text>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <SliderHandlerV2
                      :key="generateUniqueId()"
                      title="Galerie"
                      :entity-data="entityData"
                      attribute-name="sliderImages"
                      :dragging="dragging"
                      :show-slide-form="showSlideForm"
                      :icons="icons"
                      @removeSlide="removeSlide($event)"
                    ></SliderHandlerV2>

                    <br>

                    <v-divider class="v-divider-entity mt-5 mb-5"></v-divider>

                    <ImageHandler
                      :key="generateUniqueId()"
                      :entity-data="entityData"
                      image-label="Photo principale"
                      image-name="mainImage"
                      :icons="icons"
                      :valid="valid"
                      :loading="loading"
                      :success-message="successMessage"
                      :snackbar="snackbar"
                      @update="updateEntityData($event)"
                    ></ImageHandler>

                    <v-divider class="v-divider-entity mt-2 mb-5"></v-divider>

                    <ImageHandler
                      :key="generateUniqueId()"
                      :entity-data="entityData"
                      image-label="Photo mobile"
                      image-name="mobileImage"
                      :icons="icons"
                      :valid="valid"
                      :loading="loading"
                      :success-message="successMessage"
                      :snackbar="snackbar"
                      @update="updateEntityData($event)"
                    ></ImageHandler>

                    <v-divider class="v-divider-entity mt-2 mb-5"></v-divider>

                    <ImageHandler
                      :key="generateUniqueId()"
                      :entity-data="entityData"
                      image-label="Photo du gérant"
                      image-name="managerImage"
                      :icons="icons"
                      :valid="valid"
                      :loading="loading"
                      :success-message="successMessage"
                      :snackbar="snackbar"
                      @update="updateEntityData($event)"
                    ></ImageHandler>

                    <v-divider class="v-divider-entity mt-2 mb-5"></v-divider>

                    <ImageHandler
                      :key="generateUniqueId()"
                      :entity-data="entityData"
                      image-label="Plan du camping"
                      image-name="planImage"
                      :icons="icons"
                      :valid="valid"
                      :loading="loading"
                      :success-message="successMessage"
                      :snackbar="snackbar"
                      @update="updateEntityData($event)"
                    ></ImageHandler>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-btn
              class="mt-5 mr-2"
              color="primary"
              :disabled="!valid"
              type="submit"
            >
              Valider
            </v-btn>

            <v-btn
              class="mt-5"
              text
              :to="{ name: 'campingList' }"
            >
              Retour
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Vue from 'vue'
import { mdiEyeOutline, mdiEyeOffOutline, mdiCameraOutline, mdiMinusBox, mdiPencil, mdiCompassRose, mdiThumbUp, mdiSkiWater } from '@mdi/js'
import config from '../../config'
import LocalSwitcher from '@/components/Common/LocalSwitcher'
import Snackbar from '@/components/Common/Snackbar'
import Loader from '@/components/Common/Loader'
import ImageHandler from '@/components/Common/ImageHandler'
import Draggable from 'vuedraggable'
import SliderHandlerV2 from '@/components/Common/SliderHandlerV2'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import FullEditor from 'ckeditor5-custom-build/build/ckeditor'
import VueTagsInput from '@johmun/vue-tags-input'

export default {
  components: {
    SliderHandlerV2,
    ImageHandler,
    Snackbar,
    LocalSwitcher,
    Loader,
    Draggable,
    VueTagsInput,
    ckeditor: CKEditor.component
  },
  data () {
    return {
      valid: false,
      errors: [],
      snackbar: false,
      successMessage: null,
      loading: false,
      currentLocale: 'fr',
      tag: '',
      tags: [],
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiCameraOutline,
        mdiMinusBox,
        mdiPencil,
        mdiCompassRose,
        mdiSkiWater,
        mdiThumbUp
      },
      editor: FullEditor,
      editorConfig: {
        heading: {
          options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
            { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
            { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
            { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
            { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' }
          ]
        },
        htmlSupport: {
          allow: [
            {
              name: /.*/,
              attributes: true,
              classes: true,
              styles: true
            }
          ]
        },
        simpleUpload: {
          uploadUrl: config.apiUrl + '/api/medias',
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        }
      },
      regionsWeb: [],
      regionWebSelection: [],
      id: [],
      name: [],
      slug: [],
      managerQuote: [],
      summary: [],
      title: [],
      shortDescription: [],
      longDescription: [],
      city: [],
      seoTitle: [],
      seoDescription: [],
      seoKeywords: [],
      pluses: [],
      rseTxt1: [],
      rseTxt2: [],
      rseSubtitle: [],
      rseDescription: [],
      camping: null,
      currentSlide: null,
      currentSlideTitle: null,
      currentSlidePreview: null,
      slides: [],
      list: [],
      dragging: false,
      showSlideForm: false,
      entityData: {
        id: null,
        flowerDataId: null,
        secureHolidayId: null,
        manager: null,
        address: null,
        zipCode: null,
        stars: null,
        latitude: null,
        longitude: null,
        email: null,
        website: null,
        phone: null,
        tonicLvl: null,
        zenLvl: null,
        funLvl: null,
        entertainmentLvl: null,
        nbOfRentals: null,
        urlRedirect: null,
        globalRating: null,
        reviewsCount: null,
        enabled: null,
        new: null,
        naturist: null,
        googleLabel: null,
        weight: null,
        video: null,
        rentalOpening: null,
        rentalClosing: null,
        pitchOpening: null,
        pitchClosing: null,
        customerAllianceId: null,
        customerAllianceApiId: null,
        customerAllianceApiAccessKey: null,
        advantages: [],
        activities: [],
        region: {},
        department: {},
        currentLocale: 'fr',
        defaultLocale: 'fr',
        newTranslations: {},
        tags: [],
        mainImage: {
          id: null,
          title: null
        },
        managerImage: {
          id: null,
          title: null
        },
        mobileImage: {
          id: null,
          title: null
        },
        rseImage: {
          id: null,
          title: null
        },
        planImage: {
          id: null,
          title: null
        },
        sliderImages: []
      }
    }
  },
  created () {
    this.getRegionsWeb()
  },
  methods: {
    checkForm () {
      this.errors = []

      if (this.valid) {
        this.putCamping()
      }
    },
    apiUrl () {
      return config.apiUrl
    },
    getRegionsWeb () {
      this.loading = true

      const params = {
        limit: 150
      }

      Vue.prototype.$http
        .get(config.apiUrl + '/api/location/regions-web', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: params
        })
        .then(response => {
          if (response.status === 200) {
            const regionsWeb = response.data

            for (let i = 0; i < regionsWeb.length; i++) {
              const currentRegionWeb = regionsWeb[i]

              this.regionsWeb.push({
                text: currentRegionWeb.translations.fr.name,
                value: currentRegionWeb.id
              })
            }
          }

          this.getCamping()
        })
        .catch(() => {
          this.loading = false
        })
    },
    getCamping () {
      const campingId = this.$route.params.campingId

      this.loading = true

      Vue.prototype.$http
        .get(config.apiUrl + '/api/campings/' + campingId, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 200) {
            this.camping = response.data
            this.entityData.id = this.camping.id

            if (this.camping.mainImage) {
              this.entityData.mainImage = this.camping.mainImage
            }

            if (this.camping.activity) {
              this.entityData.activity = this.camping.activity
            }

            if (this.camping.mobileImage) {
              this.entityData.mobileImage = this.camping.mobileImage
            }

            if (this.camping.managerImage) {
              this.entityData.managerImage = this.camping.managerImage
            }

            if (this.camping.rseImage) {
              this.entityData.rseImage = this.camping.rseImage
            }

            if (this.camping.planImage) {
              this.entityData.planImage = this.camping.planImage
            }

            if (this.camping.sliderImages) {
              this.entityData.sliderImages = this.camping.sliderImages
            }

            this.entityData.regionWeb = this.camping.regionWeb

            if (this.entityData.regionWeb) {
              this.regionWebSelection = this.entityData.regionWeb.id
            }

            this.entityData.flowerDataId = this.camping.flowerDataId
            this.entityData.secureHolidayId = this.camping.secureHolidayId
            this.entityData.manager = this.camping.manager
            this.entityData.address = this.camping.address
            this.entityData.zipCode = this.camping.zipCode
            this.entityData.stars = this.camping.stars
            this.entityData.latitude = this.camping.latitude
            this.entityData.longitude = this.camping.longitude
            this.entityData.email = this.camping.email
            this.entityData.website = this.camping.website
            this.entityData.phone = this.camping.phone
            this.entityData.tonicLvl = this.camping.tonicLvl
            this.entityData.zenLvl = this.camping.zenLvl
            this.entityData.funLvl = this.camping.funLvl
            this.entityData.entertainmentLvl = this.camping.entertainmentLvl
            this.entityData.nbOfRentals = this.camping.nbOfRentals
            this.entityData.urlRedirect = this.camping.urlRedirect
            this.entityData.globalRating = this.camping.globalRating
            this.entityData.reviewsCount = this.camping.reviewsCount
            this.entityData.enabled = this.camping.enabled
            this.entityData.new = this.camping.new
            this.entityData.naturist = this.camping.naturist
            this.entityData.googleLabel = this.camping.googleLabel
            this.entityData.weight = this.camping.weight
            this.entityData.video = this.camping.video
            this.entityData.rentalOpening = this.camping.rentalOpening
            this.entityData.rentalClosing = this.camping.rentalClosing
            this.entityData.pitchOpening = this.camping.pitchOpening
            this.entityData.pitchClosing = this.camping.pitchClosing
            this.entityData.customerAllianceId = this.camping.customerAllianceId
            this.entityData.customerAllianceApiId = this.camping.customerAllianceApiId
            this.entityData.customerAllianceApiAccessKey = this.camping.customerAllianceApiAccessKey
            this.entityData.region = this.camping.region
            this.entityData.department = this.camping.department

            this.entityData.advantages = this.camping.advantages
            this.entityData.activities = this.camping.activities

            const translations = this.camping.translations

            for (const [currentKey] of Object.entries(translations)) {
              let currentTranslation = translations[currentKey]

              this.id[currentKey] = currentTranslation.id
              this.managerQuote[currentKey] = currentTranslation.managerQuote
              this.name[currentKey] = currentTranslation.name
              this.slug[currentKey] = currentTranslation.slug
              this.summary[currentKey] = currentTranslation.summary
              this.title[currentKey] = currentTranslation.title
              this.shortDescription[currentKey] = currentTranslation.shortDescription
              this.longDescription[currentKey] = currentTranslation.longDescription
              this.city[currentKey] = currentTranslation.city
              this.seoTitle[currentKey] = currentTranslation.seoTitle
              this.seoDescription[currentKey] = currentTranslation.seoDescription
              this.seoKeywords[currentKey] = currentTranslation.seoKeywords
              this.pluses[currentKey] = currentTranslation.pluses
              this.rseTxt1[currentKey] = currentTranslation.rseTxt1
              this.rseTxt2[currentKey] = currentTranslation.rseTxt2
              this.rseSubtitle[currentKey] = currentTranslation.rseSubtitle
              this.rseDescription[currentKey] = currentTranslation.rseDescription
            }

            for (let i = 0; i < this.entityData.advantages.length; i++) {
              const currentAdvantage = this.entityData.advantages[i]
              const descriptionValues = {}

              for (const [currentKey] of Object.entries(currentAdvantage.translations)) {
                let currentTranslation = currentAdvantage.translations[currentKey]

                descriptionValues[currentKey] = currentTranslation.description

                currentAdvantage.description = descriptionValues
              }
            }

            for (let i = 0; i < this.entityData.activities.length; i++) {
              const currentActivity = this.entityData.activities[i]
              const descriptionValues = {}
              const titleValues = {}

              for (const [currentKey] of Object.entries(currentActivity.translations)) {
                let currentTranslation = currentActivity.translations[currentKey]

                descriptionValues[currentKey] = currentTranslation.description

                currentActivity.description = descriptionValues

                titleValues[currentKey] = currentTranslation.title

                currentActivity.title = titleValues
              }
            }

            for (let i = 0; this.camping.tags.length > i; i++) {
              this.tags[i] = {'text': this.camping.tags[i]}
            }

            this.valid = true
          }

          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    putCamping () {
      this.loading = true
      this.valid = false
      this.successMessage = null

      this.handleTranslations([
        'id',
        'name',
        'managerQuote',
        'summary',
        'title',
        'shortDescription',
        'longDescription',
        'city',
        'seoTitle',
        'seoDescription',
        'seoKeywords',
        'pluses',
        'rseTxt1',
        'rseTxt2',
        'rseSubtitle',
        'rseDescription'
      ])

      this.handleAdvantagesTranslations(this.entityData.advantages)

      this.handleActivitiesTranslations(this.entityData.activities)

      for (let i = 0; i < this.entityData.advantages.length; i++) {
        this.entityData.advantages[i].position = i
      }

      for (let i = 0; i < this.entityData.activities.length; i++) {
        this.entityData.activities[i].position = i
      }

      if (this.regionWebSelection.length) {
        this.entityData.regionWeb = {
          id: this.regionWebSelection
        }
      }

      for (let i = 0; this.entityData.activities.length > i; i++) {
        const currentActivityImage = this.entityData.activities[i].image

        this.entityData.activities[i].image = {
          id: currentActivityImage.id,
          name: currentActivityImage.name
        }
      }

      for (let i = 0; this.entityData.advantages.length > i; i++) {
        const currentAdvantageImage = this.entityData.advantages[i].image

        this.entityData.advantages[i].image = {
          id: currentAdvantageImage.id,
          name: currentAdvantageImage.name
        }
      }

      this.entityData.tags = []

      for (let i = 0; this.tags.length > i; i++) {
        this.entityData.tags[i] = this.tags[i].text
      }

      Vue.prototype.$http
        .put(config.apiUrl + '/api/campings/' + this.entityData.id, this.entityData, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 200) {
            this.camping = response.data

            const translations = this.camping.translations

            for (const [currentKey] of Object.entries(translations)) {
              let currentTranslation = translations[currentKey]

              this.id[currentKey] = currentTranslation.id
              this.managerQuote[currentKey] = currentTranslation.managerQuote
              this.summary[currentKey] = currentTranslation.summary
              this.title[currentKey] = currentTranslation.title
              this.shortDescription[currentKey] = currentTranslation.shortDescription
              this.longDescription[currentKey] = currentTranslation.longDescription
              this.city[currentKey] = currentTranslation.city
              this.seoTitle[currentKey] = currentTranslation.seoTitle
              this.seoDescription[currentKey] = currentTranslation.seoDescription
              this.seoKeywords[currentKey] = currentTranslation.seoKeywords
              this.pluses[currentKey] = currentTranslation.pluses
              this.rseTxt1[currentKey] = currentTranslation.rseTxt1
              this.rseTxt2[currentKey] = currentTranslation.rseTxt2
              this.rseSubtitle[currentKey] = currentTranslation.rseSubtitle
              this.rseDescription[currentKey] = currentTranslation.rseDescription
            }

            this.snackbar = true
            this.successMessage = 'Camping mis à jour avec succès.'
            this.valid = true
          }

          this.loading = false
        })
        .catch(e => {
          const response = e.response

          this.successMessage = null

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
          }

          this.valid = true
          this.loading = false
        })
    },
    updateEntityData (updatedData) {
      this.entityData = updatedData
    },
    updateLoading (loading) {
      this.loading = loading
    },
    updateSnackbar (snackbar) {
      this.snackbar = snackbar
    },
    updateSuccessMessage (successMessage) {
      this.successMessage = successMessage
    },
    updateValid (valid) {
      this.valid = valid
    },
    uploadSubImage (entity) {
      this.valid = false
      this.loading = true

      const formData = new FormData()

      formData.append('media', entity.file)

      Vue.prototype.$http
        .post(config.apiUrl + '/api/medias', formData, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken,
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          if (response.status === 201) {
            const media = response.data

            entity.image.id = media.id
            entity.image.name = media.name

            this.errors = []
            this.snackbar = true
            this.successMessage = 'Image envoyé avec succès.'
          }

          this.valid = true
          this.loading = false
        })
        .catch(e => {
          const response = e.response

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
          }

          this.valid = true
          this.loading = false
        })
    },
    updateSubImageFile (data) {
      const file = data.file

      data.preview = URL.createObjectURL(file)
    },
    editSubImage (data) {
      this.successMessage = null

      data.image.id = null
      data.preview = null
    },
    previewSlide (file) {
      this.currentSlide = file
      this.currentSlidePreview = URL.createObjectURL(file)
      this.showSlideForm = true
    },
    removeSlide (slide) {
      this.entityData.sliderImages = this.entityData.sliderImages.filter(element => element.id !== slide.id)
    },
    handleTranslations (attributes) {
      for (let i = 0; i < attributes.length; i++) {
        const currentAttribute = attributes[i]

        for (const [currentKey, currentValue] of Object.entries(this[currentAttribute])) {
          let translation = {
            locale: currentKey,
            empty: false
          }

          translation[currentAttribute] = currentValue

          const currentObjState = this.entityData.newTranslations[currentKey]

          this.entityData.newTranslations[currentKey] = {...currentObjState, ...translation}
        }
      }
    },
    handleAdvantagesTranslations (advantages) {
      const handledAdvantages = []

      for (let i = 0; i < advantages.length; i++) {
        const currentAdvantage = advantages[i]

        const descriptionTranslations = currentAdvantage.description

        currentAdvantage.newTranslations = {}

        for (const [currentKey, currentValue] of Object.entries(descriptionTranslations)) {
          let translation = {
            locale: currentKey,
            empty: false
          }

          translation['description'] = currentValue

          const currentObjState = currentAdvantage.newTranslations[currentKey]

          currentAdvantage.newTranslations[currentKey] = {...currentObjState, ...translation}
        }

        handledAdvantages.push(currentAdvantage)
      }

      this.entityData.advantages = handledAdvantages
    },
    handleActivitiesTranslations (activities) {
      const handledActivities = []

      for (let i = 0; i < activities.length; i++) {
        const currentActivity = activities[i]

        currentActivity.newTranslations = {}

        const attributes = [
          'title',
          'description'
        ]

        for (let i = 0; i < attributes.length; i++) {
          const currentAttribute = attributes[i]

          for (const [currentKey, currentValue] of Object.entries(currentActivity[currentAttribute])) {
            let translation = {
              locale: currentKey,
              empty: false
            }

            translation[currentAttribute] = currentValue

            const currentObjState = currentActivity.newTranslations[currentKey]

            currentActivity.newTranslations[currentKey] = {...currentObjState, ...translation}
          }
        }

        handledActivities.push(currentActivity)
      }

      this.entityData.activities = handledActivities
    },
    updateCurrentLocale (locale) {
      this.currentLocale = locale
      this.successMessage = null
    },
    formatDate (currentDate) {
      if (currentDate) {
        const timestamp = Date.parse(currentDate)
        const date = new Date(timestamp)
        let day = date.getDate()
        let month = date.getMonth() + 1

        day = (day < 10 ? '0' : '') + day
        month = (month < 10 ? '0' : '') + month

        return day + '/' + month + '/' + date.getFullYear()
      }

      return currentDate
    },
    addAdvantageForm () {
      this.entityData.advantages.push({
        image: {},
        currentLocale: 'fr',
        defaultLocale: 'fr',
        newTranslations: {},
        description: {},
        position: null
      })
    },
    removeAdvantageForm (advantageIndex) {
      this.entityData.advantages.splice(advantageIndex, 1)
    },
    addActivityForm () {
      this.entityData.activities.push({
        image: {},
        currentLocale: 'fr',
        defaultLocale: 'fr',
        newTranslations: {},
        title: {},
        description: {},
        website: null,
        position: null
      })
    },
    removeActivityForm (activityIndex) {
      this.entityData.activities.splice(activityIndex, 1)
    }
  }
}
</script>

